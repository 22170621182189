import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { Row, Col, Card, Carousel, Accordion, ButtonGroup, Button } from 'react-bootstrap'
import { GlobalSet } from '../../global/GlobalProvider';
import { Link } from "react-router-dom";
import axios from "axios";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import swal from "sweetalert";

const BasicDatatable = () => {
  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );
  const sort = 5;
  const activePag = useRef(0);
  const { CurrencySymbol } = GlobalSet();
  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
   // chackboxFun();
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };



 /*.....income data......*/

 const { t } = useTranslation();
 const [income, setIncome] = useState([]);
    useEffect(()=>{
      const selectedCurrency = localStorage.getItem('selectedCurrency');
    	const auth_token = localStorage.getItem('auth_token');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
    	if (auth_token !== null) {
    		 axios.post('/smart-team',data)
       	 .then(response=>{
           setIncome(response.data.data)
           console.log(response.data.data)
           
			     })
			     .catch(error=>{
			       console.log(error)
			   })
    	}

    },[])



    const [depositInput, setDepositInput] = useState({
      username: '',
      error_list: [],
    });

    const handleInput = (e) => {
      e.persist();
      setDepositInput({...depositInput, [e.target.name]: e.target.value });
    }

    const [loading, setLoading] = useState(false);
    const depositSubmit = (e) => {
      e.preventDefault();
      const auth_token = localStorage.getItem('auth_token');
      const data = {
          username: depositInput.username,
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9'
      }
     if (auth_token !== null && !loading) {
        setLoading(true);
        axios.post(`/check-email-level`, data).then(res => {
           if(res.data.status === 'success')
           {
             swal("Your Team",res.data.data.email,"success");
             setDepositInput({...depositInput,
               username:'',
               
             });
           }
           else if(res.data.status === 'fail')
           {
             swal("Warning",res.data.message,"warning");
           }
           else 
           {
           setDepositInput({...depositInput, error_list: res.data.validation_error});
           }
           setLoading(false);
       });
     }
    }



  return (
    <div className="col-12">
{/*            <div className="col-md-8 order-md-1">
               <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                  <div className="row">
                     <div className="col-md-6 mb-3">
                        <input
                           type="email"
                           className="form-control"
                           id="lastName"required
                           placeholder="Email"
                           name="username"
                           onChange={handleInput} 
                           value={depositInput.username}
                        />
                           <span className="text-danger">{depositInput.error_list.username}</span>
                     </div>

                     <div className="col-md-6 mb-3">

                      <button
                         className="btn btn-warning btn-sm btn-block"
                         type="submit"
                         disabled={loading}
                      >
                        {loading ? 'Searching...' : 'Search Continue'}
                      </button>
                        
                     </div>
                  </div>

               </form>
            </div>*/}

      <div className="card">
        <div className="card-body">
            <Col xl="12" className="p-0" >
             <div className="App">
                <div className="table-responsive card booking">
                      <table
                        className="display w-100 dataTable "
                        id="example5"
                        role="grid"
                        aria-describedby="example5_info"
                         style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis', background: '#2f2f2f'}}
                      >
                        <tbody>
                          <tr className="odd" role="row">

                            <td> <Link to={"/my-smart-team"} className="text-black">All Level </Link></td>
                            <td> <Link to={"/view-team/1/level-a"} className="text-black">Level 1 </Link></td>
                            <td> <Link to={"/view-team/2/level-b"} className="text-black">Level 2 </Link></td>
                            <td> <Link to={"/view-team/3/level-c"} className="text-black">Level 3 </Link></td>
                            <td> <Link to={"/view-team/4/level-d"} className="text-black">Level 4 </Link></td>
                            <td> <Link to={"/view-team/5/level-e"} className="text-black">Level 5 </Link></td>
                            <td> <Link to={"/view-team/6/level-f"} className="text-black">Level 6 </Link></td>
                            <td> <Link to={"/view-team/7/level-g"} className="text-black">Level 7 </Link></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
              </div>
            </Col>
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc" style={{ width: "278px" }}>
                     SL
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      {t('pro_his_bet_type')}
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      {t('pro_his_odds_name')}
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      Self Sell
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      Self And Team
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      Team Sells
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      Join Date
                    </th>
                  </tr>
                </thead>


                <tbody>
                {income.map((incomerow, index)=>(
                  <tr key={incomerow.id} className="odd" role="row">
                    <td className="sorting_1">{index + 1}</td>
                    <td className="sorting_1">{incomerow.email}</td>
                    <td>{incomerow.count_active_spons} Members</td>
                    <td>${incomerow.total_invest }</td>
                    <td>${incomerow.team_and_spon_sell }</td>
                    <td>${incomerow.team_sell}</td>
                    <td>{ incomerow.created_at}</td>
                  </tr>
                  ))}
                </tbody>
               
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/my-smart-team"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/my-smart-team"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/my-smart-team"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDatatable;
